<template>
  <div class="mb-3">
<!--    <card-v2>-->
      <a-descriptions  bordered>
        <a-descriptions-item :label="$t('first_name') ">
          {{data.first_name}}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('last_name')">
          {{data.last_name}}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('middle_name')">
          <div v-if="data.middle_name">
            {{data.middle_name}}
          </div>
          <div v-else>
            {{ $t('not_specified') }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('created')">
          {{formattedDate(data.created)}}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('iin')">
          <div v-if="data.iin">
            {{data.iin}}
          </div>
          <div v-else>
            {{ $t('not_specified') }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item :label="$t('email')" span="2">
          <div v-if="data.email">
            {{data.email}}
          </div>
          <div v-else>
            {{ $t('not_specified') }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item :label="item.main?$t('main_number'):$t('additional_number')" v-for="(item, index) in data.phone_numbers" :key="index">
          {{item.number}}
        </a-descriptions-item>


      </a-descriptions>
<!--    </card-v2>-->
  </div>
</template>

<script>
import CardV2 from "@/crm_components/card_v2/CardV2";
import InfoDrawer from "@/crm_components/CustomDrawer/InfoDrawer";
import FormattedDateMixin from "@/crm_components/mixins/FormattedDateMixin";

export default {
  name: "ClientInfo",
  components: {
    InfoDrawer,
    CardV2,
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
  },
  mixins: [
    FormattedDateMixin,
  ],
  computed: {
  },
};
</script>

<style scoped>
/* Any scoped styles can be added here if needed */
</style>
