<template>
  <div>
    <div class="mb-4">
      {{data.description}}
    </div>
    <div v-if="data.map_requests">
      <form-select :cols="1" :show_label="true" :formItems="formItemsOnlyMap" ref="refFormUpdateRequestMap"/>
    </div>
    <div v-if="data.attachments.length > 0">
      <form-select :cols="1" :show_label="true" :formItems="formItemsFiles" ref="refFormUpdateRequestFiles"/>
    </div>

  </div>
</template>

<script>
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
export default {
  name: "RequestInfo",
  components: {
    FormSelect
  },
  props:{
    data:{
      type: Object,
      default: {}
    },
    visible:{
      type:Boolean,
      default: false
    },
  },
  watch:{
    visible(val){
      if (val){
        this.set_data()
      }
    }
  },
  async mounted() {
    await this.set_data()
  },
  methods:{
    async set_data(){
      await this.$nextTick();
      let clone_client_data = {}
      clone_client_data['map_requests'] = this.data.map_requests
      clone_client_data['map_requests_value'] = this.data.map_requests?this.data.map_requests.full_address:null
      if (this.data.map_requests){
        this.$refs.refFormUpdateRequestMap.markers = [{ position: this.data.map_requests }];
        this.$refs.refFormUpdateRequestMap.center = {
          lat: this.data.map_requests.lat,
          lng: this.data.map_requests.lng
        }
      }
      this.$refs.refFormUpdateRequestMap.clientData = clone_client_data


      clone_client_data = {}
      clone_client_data['attachments'] = this.data.attachments
      this.$refs.refFormUpdateRequestFiles.clientData = clone_client_data

      if (this.$refs.refFormUpdateRequestFiles.$refs.refUpload){
        this.$refs.refFormUpdateRequestFiles.$refs.refUpload[0].sFileList = this.data.attachments
      }

    }
  },
  data(){
    return{
      formItemsOnlyMap:[
        {
          model: 'map_requests',
          type:'map',
          read_only:true,
          vertically:true
        },
      ],
      formItemsFiles:[
        {
          model: 'attachments',
          type:'upload',
          read_only:true,
          vertically:true
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
