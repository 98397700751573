<template>
<div>
  <request :visible="visible" @edit="edit" :data="data" @close="close" @go_client="go_client"/>
  <request-edit :visible="visible_edit" :data="data" ref="refRequestEdit" @close="close_edit" @update_request="update_request"/>
</div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import DescriptionInfoRequest from "@/pages/AddClient/Info/DescriptionInfoRequest";
import CardV2 from "@/crm_components/card_v2/CardV2";
import EditFiles from "@/crm_components/files/EditFiles";
import Tiptap from "@/components/Tiptap/Tiptap";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import RequestEdit from "@/pages/AddClient/Request/RequestEdit";
import Request from "@/pages/AddClient/Request/Request";
export default {
  name: "RequestShowDrawer",
  components: {
    Request,
    RequestEdit,
    Tiptap,
    EditFiles,
    DescriptionInfoRequest,
    CustomDrawer,
    ClientInfo,
    CustomTabs,
    CardV2,
    ColorIcon
  },
  data(){
    return {
      data: null,
      visible_edit: false,
      visible: false,
    }
  },
  props:{
  },
  methods:{
    go_client(val){
      this.$emit('go_client', val)
    },
    update_request(val){
      console.log('valasdadasdasd', val)
      this.data.data = val.data
      this.close_edit()
    },
    async edit(){
      this.visible = false
      this.visible_edit = true
      await this.$nextTick(); // Подождем, пока DOM обновится

      // Теперь ссылки на компоненты должны быть доступны
      let item = this.data.data
      let clone_client_data = {
        uid: item.uid,
        description: item.description,
        name: item.name,
        // address: item.address,
      }

      if (item.level_request){
        clone_client_data['level_request'] = item.level_request.uid
      }
      if (item.responsible_manager){
        clone_client_data['responsible_manager'] = item.responsible_manager.uid
      }
      if (item.organization_request){
        clone_client_data['organization_request'] = item.organization_request.uid
      }
      if (item.source_request){
        clone_client_data['source_request'] = item.source_request.uid
      }
      if (item.status_request){
        clone_client_data['status_request'] = item.status_request.uid
      }
      if (item.result_request){
        clone_client_data['result_request'] = item.result_request.uid
      }
      if (item.request_type){
        clone_client_data['request_type'] = item.request_type.uid
      }
      if (item.region_fk){
        clone_client_data['region_fk'] = item.region_fk.uid
      }
      clone_client_data['attachments'] = item.attachments
      clone_client_data['health_threat'] = item.health_threat
      clone_client_data['no_address_required'] = item.no_address_required

      clone_client_data['map_requests'] = item.map_requests
      clone_client_data['map_requests_value'] = item.map_requests?item.map_requests.full_address:null
      if (item.map_requests){
        console.log('this.$refs.refRequestEdit.$refs.refFormUpdateRequest.markers', this.$refs.refRequestEdit.$refs.refFormUpdateRequest.markers)
        this.$refs.refRequestEdit.$refs.refFormUpdateRequest.markers = [{ position: item.map_requests }];
        console.log('item.map_requests;', item.map_requests)
        console.log(' this.$refs.refRequestEdit.$refs.refFormUpdateRequest',  this.$refs.refRequestEdit.$refs.refFormUpdateRequest)
        this.$refs.refRequestEdit.$refs.refFormUpdateRequest._data.center = {
          lat: item.map_requests.latitude,
          lng: item.map_requests.longitude
        }
        console.log('this.$refs.refRequestEdit.$refs.refFormUpdateRequest.center ', this.$refs.refRequestEdit.$refs.refFormUpdateRequest.center )
      }


      if (this.$refs.refRequestEdit.$refs.refFormUpdateRequest.$refs.refUpload){
        this.$refs.refRequestEdit.$refs.refFormUpdateRequest.$refs.refUpload[0].sFileList = item.attachments
      }

      if (this.$refs.refRequestEdit.$refs.refFormUpdateRequest) {
        this.$refs.refRequestEdit.$refs.refFormUpdateRequest.fileList = []
        this.$refs.refRequestEdit.$refs.refFormUpdateRequest.clientData = clone_client_data
      } else {
        console.error('Reference to refFormEdit is still undefined.');
      }
      console.log('this.$refs.refFormEdit', this.$refs.refFormEdit)
      console.log('clone_client_data', clone_client_data)
    },
    close_edit(){
      this.visible_edit = false
      this.visible = true
    },
    close(){
      this.visible = false
      this.visible_edit = false
    },
  }
}
</script>

<style scoped>

</style>
