<script>
export default {
  name: "OpenDrawerRequestMixin",
  methods:{
    async open_drawer_request(data, edit = false){
      console.log('data', data)

      this.$refs.refRequestShowDrawer.data = data
      // await this.$refs.refCustomDrawer.onClose()
      try {
        console.log('this.$route.query', this.$route.query)
        await this.$router.push({query:{...this.$route.query, customer_request: data.data.uid}})

      }catch (e) {
      }

      if (this.$refs.refClientShowDrawer){
        this.$refs.refClientShowDrawer.visible = await false
        this.$refs.refClientShowDrawer.visible_edit = await false
      }

      if (edit){
        await this.$refs.refRequestShowDrawer.edit()
      }else{
        this.$refs.refRequestShowDrawer.visible = true
        this.$refs.refRequestShowDrawer.visible_edit = false
      }



      await this.$nextTick(); // Подождем, пока DOM обновится
    },
  }
}
</script>

