<template>
  <div>
    <custom-drawer :visible="visible" @close="close">
      <template slot="title">
        <div v-if="data">
          #{{ data.data.request_number }} {{ data.data.name }}
        </div>
      </template>
      <template slot="tabs">
        <custom-tabs :tabs="tabs" :visible="visible"/>
      </template>
      <template slot="content">
        <div class="flex w-full" style="margin-top: 100px;margin-bottom: 100px;">
          <div class="w-2/3">
            <div v-if="$route.query.stab === 'basic'">
              <request-info :data="data.data" :visible="visible"/>
<!--              {{ data.data.description }}-->
            </div>
            <div v-else-if="$route.query.stab === 'files'">
              <edit-files ref="refEditFiles" :defaultFileList="data.data.attachments"/>
            </div>
          </div>
          <div class="w-1/3">
            <div style="padding-left: 25px;">
              <card-v2 style="height: max-content;">
<!--                <description-info-request v-if="data" :data="data.data"/>-->
                <request-right-info v-if="data" :data="data.data" @go_client="go_client"/>
              </card-v2>
            </div>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-dropdown>
          <color-icon/>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="edit">
                {{$t('edit')}}
              </a>
            </a-menu-item>
            <a-menu-item @click="delete_request">
              <a>
                {{$t('delete')}}
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import DescriptionInfoRequest from "@/pages/AddClient/Info/DescriptionInfoRequest";
import CardV2 from "@/crm_components/card_v2/CardV2";
import EditFiles from "@/crm_components/files/EditFiles";
import Tiptap from "@/components/Tiptap/Tiptap";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import RequestInfo from "@/pages/AddClient/Info/RequestInfo";
import RequestRightInfo from "@/pages/AddClient/Info/RequestRightInfo";
export default {
  name: "Request",
  components: {
    RequestRightInfo,
    RequestInfo,
    Tiptap,
    EditFiles,
    DescriptionInfoRequest,
    CustomDrawer,
    ClientInfo,
    CustomTabs,
    CardV2,
    ColorIcon
  },
  data(){
    return {
      tabs:[
        {name:this.$t('basic'), key:'basic'},
        // {name:this.$t('files'), key:'files'},
        // {name:this.$t('client'), key:'client'},
      ],
    }
  },
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  mixins:[
    DeleteMixin,
  ],
  methods:{
    edit(){
      this.$emit('edit')
    },
    delete_request(){
      this.delete_method('create_request', 'organizations/customer/', this.data.data)
    },
    close(){
      this.$emit('close')
    },
    go_client(val){
      this.$emit('go_client', val)
    }
  }
}
</script>

<style scoped>

</style>
