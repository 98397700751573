<template>
  <div>
    <custom-drawer :visible="visible" :width="'900px'" @close="close">
      <template slot="title">
        <div>
          {{ $t('edit') }}
        </div>
      </template>
      <template slot="content">
        <div class="w-full">
          <div style="margin-top: 50px; margin-bottom: 100px;" class="w-1/2">
            <form-select :cols="1" :show_label="true" :formItems="form_items_update" ref="refFormUpdateRequest"/>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            type="primary"
            class="default-button mr-1"
            size="small"
            @click="cloneHandleSubmit">
          Сохранить
        </a-button>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import FormItemsUpdate from "@/pages/AddClient/RequestMixins/FormItemsUpdate";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import AntSelect from "@/components/AntSelect/AntSelect";
import FormSaveMixin from "@/crm_components/form_save/FormSaveMixin";
export default {
  name: "RequestEdit",
  components: {
    CustomDrawer,
    FormSelect,
    AntSelect
  },
  data(){
    return {
    }
  },
  mixins: [FormItemsUpdate, FormSaveMixin,],
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  methods:{
    afterUpdate(val){
      this.$emit('update_request', val)
    },
    cloneHandleSubmit(){
      console.log('this.$refs.refFormUpdateRequest.clientData.attachments', this.$refs.refFormUpdateRequest.clientData.attachments)
      this.$refs.refFormUpdateRequest.clientData = {
        ...this.$refs.refFormUpdateRequest.clientData,
        attachments:this.$refs.refFormUpdateRequest.clientData.attachments.map((item) => {
          if (typeof item === 'string'){
            return item
          }else{
            return item.uid
          }
        })
      }
      this.handleSubmit(this.$refs.refFormUpdateRequest, null, `organizations/customer/`, 'update')
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
