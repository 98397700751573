<template>
  <div>
    <custom-drawer :visible="visible" ref="refClientAndRequestCreate" :key_delete_router="[]" @close="close">
      <template slot="title">
        <div>
          {{title}}
        </div>
      </template>
<!--      <template slot="tabs">-->
<!--        <custom-tabs :tabs="tabs"/>-->
<!--      </template>-->
      <template slot="content">
        <div class="w-full" style="margin-top: 50px; margin-bottom: 200px;">
          <div  class="w-1/2">
            <a-checkbox :checked="has_an_account" @change="onChange" class="mb-4">
              {{ $t('the_client_already_has_an_account') }}
            </a-checkbox>
            <div v-if="has_an_account">
              <FormSelect :formItems="formHasAccount" ref="refFormCreateClientHasAccount" :cols="1"/>
            </div>
            <div v-else>
              <FormSelect
                  :formItems="formItemsCreate"
                  ref="refFormCreateClientNoAccount" :cols="1"
                  @iin_already_exists="iin_already_exists"
                  @number_already_exists="number_already_exists"/>
            </div>
          </div>
          <a-checkbox :checked="add_request" @change="on_add_request" class="mb-4">
            {{ $t('add_request') }}
          </a-checkbox>
          <a-collapse accordion class="mb-2" default-active-key="1" v-if="add_request">
            <a-collapse-panel key="1">
              <template slot="header">
                <a-button type="link" style="font-size: 17px;">
                  {{$t('create_request')}}
                </a-button>
              </template>
              <div class="w-1/2">
<!--                <div class="mb-2">-->
<!--                  <span>{{$t('health_threat')}}</span>-->
<!--                  <a-switch v-model="health_threat" @change="onChangeHealthThreat" />-->
<!--                </div>-->
                <FormSelect :formItems="formItemsCreateRequest" ref="refFormCreateRequest" :cols="1" class="mb-2"/>
<!--                <div class="mb-4">-->
<!--                  <span>{{$t('no_address_required')}}</span>-->
<!--                  <a-switch v-model="no_address_required" @change="onChangeNoAddressRequired" />-->
<!--                </div>-->

<!--                <div v-if="!no_address_required" class="mb-2">-->
<!--                  <span>{{$t('address')}}<span class="text-red-500">*</span></span>-->
<!--                  <google-map ref="refMapRequest"/>-->
<!--                </div>-->
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </template>
      <template slot="content_bottom">
        <a-button
            type="primary"
            class="default-button mr-1"
            size="small"
            @click="create_client_and_request">
          Сохранить
        </a-button>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import FormSelectItems from "@/pages/AddClient/ClientMixins/FormSelectItems";
import FormItemsUpdate from "@/pages/AddClient/RequestMixins/FormItemsUpdate";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
export default {
  name: "ClientAndRequest",
  components:{
    GoogleMap,
    CustomDrawer,
    FormSelect,
    CustomTabs,
  },
  props:{
    name_event_bus:{
      type:String,
      default: 'clients_event_bus'
    },
    title:{
      type:String,
      default: ''
    },
  },
  data(){
    return{
      visible:false,
      has_an_account:false,
      no_address_required:false,
      health_threat:false,
      add_request:false,
      // tabs:[
      //   {name:this.$t('requests'), key:'requests'},
      // ],
    }
  },
  mixins:[
      FormSelectItems,
      FormItemsUpdate,
      formSaveMixin,
  ],
  methods:{
    async create_client_and_request(){
      try {
        let refForm
        if (this.has_an_account){
          refForm = this.$refs.refFormCreateClientHasAccount
        }else{
          refForm = this.$refs.refFormCreateClientNoAccount
          if (refForm.clientData.iin === ''){
            refForm.clientData.iin = null
          }
        }

        let valid_map = true
        let valid_request = true
        if (this.$refs.refFormCreateRequest){

          valid_request = await this.$refs.refFormCreateRequest.$refs.refValidationObserver.validate()
          console.log('this.$refs.refFormCreateRequest.clientData', this.$refs.refFormCreateRequest)
          console.log('this.$refs.refFormCreateRequest.clientData', this.$refs.refFormCreateRequest.clientData)
          console.log('await this.$refs.refFormCreateRequest.$refs.refValidationObserver', await this.$refs.refFormCreateRequest.$refs.refValidationObserver)
          refForm.clientData['request'] = await this.$refs.refFormCreateRequest.clientData
          // refForm.clientData['request']['no_address_required'] = this.no_address_required
          // refForm.clientData['request']['health_threat'] = this.health_threat

          // if (!this.no_address_required){
          //   refForm.clientData['request']['map_requests'] = await this.$refs.refMapRequest.address
          //   valid_map = await this.$refs.refMapRequest.$refs.refValidationObserver.validate()
          // }

        }
        // console.log('valid_map', valid_map)
        // console.log('this.$refs.refMapRequest.$refs.refValidationObserver', this.$refs.refMapRequest.$refs.refValidationObserver)
        // console.log('valid_request', valid_request)
        // console.log('this.$refs.refFormCreateRequest.$refs.refValidationObserver', this.$refs.refFormCreateRequest.$refs.refValidationObserver)

        let valid = valid_request && valid_map
        // console.log('valid', valid)

        await this.handleSubmit(
            refForm,
            this.name_event_bus,
            'organizations/clients/',
            'create',
            null,
            valid
        )
      }catch (e) {
        console.log('create_client_and_request', e)
      }


    },
    onChange(e) {
      this.has_an_account = e.target.checked;
    },
    on_add_request(e) {
      this.add_request = e.target.checked;
    },
    async number_already_exists(message){
      this.has_an_account = true
      await this.$nextTick();
      this.$refs.refFormCreateClientHasAccount.clientData['client'] = message.data.uid
      this.$refs.refFormCreateClientHasAccount.clientData['number'] = null
      console.log('message', message)
    },
    async iin_already_exists(message){
      this.has_an_account = true
      await this.$nextTick();
      this.$refs.refFormCreateClientHasAccount.clientData['client'] = message.uid
      this.$refs.refFormCreateClientHasAccount.clientData['number'] = null
      console.log('message', message)
    },
    onChangeNoAddressRequired(e){
      this.no_address_required = e
    },
    onChangeHealthThreat(e){
      this.health_threat = e
    },
    close(){
      this.visible = false
    },
    afterCreate(val){
      val.refForm.clientData = {}
      try {
        if (this.$refs.refFormCreateClientHasAccount){
          this.$refs.refFormCreateClientHasAccount.$refs.refValidationObserver.reset()
        }
      }catch (e) {

      }
      try {
        if (this.$refs.refFormCreateClientNoAccount) {
          this.$refs.refFormCreateClientNoAccount.$refs.refValidationObserver.reset()
        }
      }catch (e) {

      }
      if (this.$refs.refFormCreateRequest){
        this.$refs.refFormCreateRequest.clientData = {}
        this.$refs.refFormCreateRequest.markers = []
        navigator.geolocation.getCurrentPosition(position => {
          this.$refs.refFormCreateRequest.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
        });
        try {
          this.$refs.refFormCreateRequest.$refs.refValidationObserver.reset()
        }catch (e) {

        }

        if (this.$refs.refFormCreateRequest.$refs.refUpload){
          this.$refs.refFormCreateRequest.$refs.refUpload[0].sFileList = []
        }
      }
      this.$emit('after_create', val)
    },
    afterUpdate(){
      this.$emit('after_update', val)
    },
  }
}
</script>

<style scoped>

</style>
