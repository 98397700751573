import { render, staticRenderFns } from "./RequestInfo.vue?vue&type=template&id=60956783&scoped=true"
import script from "./RequestInfo.vue?vue&type=script&lang=js"
export * from "./RequestInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60956783",
  null
  
)

export default component.exports