<script>
import ProfileSelectView from "@/components/AntSelect/ProfileSelectView";
import PhoneNumber from "@/components/AntSelect/ItemViews/PhoneNumber";

export default {
  name: "FormSelectItems",
  data(){
    return{
      formItemsEdit: [
        {
          model: 'first_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'last_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'middle_name',
          show_label: true,
        },
        // {
        //   model: 'number',
        //   show_label: true,
        //   validate: 'required',
        //   placeholder: '+7',
        //   mask:'+7##########'
        // },
        {
          model: 'iin',
          show_label: true,
          validate: 'iin_length|iin_numeric',
        },
        {
          model: 'email',
          show_label: true,
          validate: 'custom_email',
        },
        {
          model: 'notes',
          show_label: true,
        }
      ],
      formItemsCreate: [
        {
          model: 'first_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'last_name',
          show_label: true,
          validate: 'required',
        },
        {
          model: 'middle_name',
          show_label: true,
        },
        {
          model: 'number',
          show_label: true,
          validate: 'required|number_exists',
          placeholder: '+7',
          mask:'+7##########'
        },
        {
          model: 'iin',
          show_label: true,
          validate: 'iin_length|iin_numeric|iin_exists',
          mask:'############'
        },
        {
          model: 'email',
          show_label: true,
          validate: 'custom_email',
        },
        {
          model: 'notes',
          show_label: true,
        }
      ],
      formHasAccount: [
        {
          model: 'client',
          type:'select',
          end_point:'organizations/clients/',
          select_list_component:PhoneNumber,
          validate: 'required',
          filter_field:'search_text',
        },
        {
          model: 'number',
          placeholder: '+7',
          mask:'+7##########',
          show_label: true,
        },
      ],
    }
  },
  computed(){

  }
}
</script>

<style scoped>

</style>
